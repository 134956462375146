var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-domain-config",
      "title": "Cài đặt domain",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "hide-header-close": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "d-flex-between w-100"
        }, [_c('BButton', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": close
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('modal.close')) + " ")]), !_vm.isEdit ? _c('BButton', {
          attrs: {
            "variant": "info",
            "disabled": _vm.loading
          },
          on: {
            "click": function click($event) {
              _vm.isEdit = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('modal.edit')) + " ")]) : _c('BButton', {
          attrs: {
            "variant": "warning",
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.handleSaveDomain
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('modal.save')) + " ")])], 1)];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_c('div', {
    staticStyle: {
      "max-height": "50vh",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.domains, function (domain, domainIndex) {
    return _c('div', {
      key: domain.id,
      staticClass: "my-50 d-flex-center justify-content-start gap-2"
    }, [_c('em', {
      staticClass: "text-right",
      staticStyle: {
        "width": "30px"
      }
    }, [_vm._v(" " + _vm._s(domainIndex + 1) + ". ")]), _vm.isEdit ? _c('div', {
      staticClass: "w-100 d-flex gap-2"
    }, [_c('ValidationProvider', {
      staticClass: "flex-grow-1",
      attrs: {
        "name": _vm.$t('packageConfig.columns.domain'),
        "rules": "required",
        "vid": domain.id
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-input', {
            attrs: {
              "id": domain.id,
              "name": _vm.$t('packageConfig.columns.domain'),
              "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('packageConfig.columns.domain')),
              "lazy-formatter": "",
              "state": validationContext.errors[0] ? false : null,
              "formatter": _vm.trimInput
            },
            model: {
              value: domain.value,
              callback: function callback($$v) {
                _vm.$set(domain, "value", $$v);
              },
              expression: "domain.value"
            }
          }), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": domain.id,
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger"
            }
          }, [_c('h6', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
        }
      }], null, true)
    }), _c('BButton', {
      staticClass: "p-50",
      attrs: {
        "variant": "flat-danger"
      },
      on: {
        "click": function click($event) {
          _vm.domains = _vm.domains.filter(function (d) {
            return d.id !== domain.id;
          });
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon",
        "size": "20"
      }
    })], 1)], 1) : _c('span', {
      staticClass: "py-50 font-weight-bolder text-dark"
    }, [_vm._v(" " + _vm._s(domain.value) + " ")])]);
  }), 0), _vm.isEdit ? _c('div', {
    staticClass: "d-flex-center justify-content-end gap-2 mt-50"
  }, [_c('BButton', {
    staticClass: "p-50 d-flex-center gap-1",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.addDomain
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon",
      "size": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('packageConfig.addDomain')) + " ")], 1)], 1) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="">
  <b-modal
    id="modal-domain-config"
    title="Cài đặt domain"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    hide-header-close
    no-close-on-backdrop
    size="lg"
    @show="handleShowModal"
  >
    <template #modal-footer="{close}">
      <div class="d-flex-between w-100">
        <BButton
          variant="secondary"
          @click="close"
        >
          {{ $t('modal.close') }}
        </BButton>

        <BButton
          v-if="!isEdit"
          variant="info"
          :disabled="loading"
          @click="isEdit = true"
        >
          {{ $t('modal.edit') }}
        </BButton>
        <BButton
          v-else
          variant="warning"
          :disabled="loading"
          @click="handleSaveDomain"
        >
          {{ $t('modal.save') }}
        </BButton>
      </div>
    </template>
    <IAmOverlay :loading="loading">
      <ValidationObserver ref="refFormObserver">
        <div style="max-height: 50vh; overflow-y: auto">
          <div
            v-for="(domain, domainIndex) in domains"
            :key="domain.id"
            class="my-50 d-flex-center justify-content-start gap-2"
          >
            <em
              class="text-right"
              style="width: 30px"
            >
              {{ domainIndex + 1 }}.
            </em>
            <div
              v-if="isEdit"
              class="w-100 d-flex gap-2"
            >
              <ValidationProvider
                #default="validationContext"
                :name="$t('packageConfig.columns.domain')"
                rules="required"
                class="flex-grow-1"
                :vid="domain.id"
              >
                <b-form-input
                  :id="domain.id"
                  v-model="domain.value"
                  :name="$t('packageConfig.columns.domain')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('packageConfig.columns.domain')}`"
                  lazy-formatter
                  :state="validationContext.errors[0] ? false : null"
                  :formatter="trimInput"
                />
                <b-tooltip
                  v-if="validationContext.errors[0]"
                  :target="domain.id"
                  triggers="hover focus"
                  placement="top"
                  boundary="viewport"
                  variant="danger"
                >
                  <h6 class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </h6>
                </b-tooltip>
              </ValidationProvider>
              <BButton
                variant="flat-danger"
                class="p-50"
                @click="domains = domains.filter(d => d.id !== domain.id)"
              >
                <feather-icon
                  icon="XIcon"
                  size="20"
                />
              </BButton>
            </div>
            <span
              v-else
              class="py-50 font-weight-bolder text-dark"
            >
              {{ domain.value }}
            </span>
          </div>
        </div>
        <div
          v-if="isEdit"
          class="d-flex-center justify-content-end gap-2 mt-50"
        >
          <BButton
            variant="outline-info"
            class="p-50 d-flex-center gap-1"
            @click="addDomain"
          >
            <feather-icon
              icon="PlusIcon"
              size="18"
            />
            {{ $t('packageConfig.addDomain') }}
          </BButton>
        </div>
      </ValidationObserver>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import {
  BModal, BFormInput, BButton, BTooltip,
} from 'bootstrap-vue'
import { nextTick, ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import useAgencyPackageConfig from '@/views/retail-package/agency-package-config/useAgencyPackageConfig'

import { trimInput } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BModal,
    BFormInput,
    BButton,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    ValidationProvider,
    ValidationObserver,
    BTooltip,
  },
  props: {
    domainList: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [Number, null],
      default: null,
    },
  },
  setup(props, { emit, root }) {
    const { changeDomain } = useAgencyPackageConfig()
    const domains = ref()
    const isEdit = ref(false)
    const blankDomain = () => ({ value: '', id: uuidv4() })
    function addDomain() {
      domains.value.push(cloneDeep(blankDomain()))
    }
    function handleShowModal() {
      nextTick(() => {
        isEdit.value = false
        domains.value = cloneDeep(props.domainList).map(i => ({
          value: i,
          id: uuidv4(),
        }))
      })
    }
    const loading = ref(false)
    const refFormObserver = ref()
    async function handleSaveDomain() {
      const isValid = await refFormObserver.value.validate()
      if (isValid) {
        loading.value = true
        await changeDomain(props.id, domains.value.map(d => d.value))
        emit('refetchData')
        isEdit.value = false
        loading.value = false
        root.$bvModal.hide('modal-domain-config')
      }
    }
    return {
      handleShowModal,
      handleSaveDomain,
      domains,
      isEdit,
      trimInput,
      loading,
      addDomain,
      refFormObserver,
    }
  },
}
</script>
<style lang="">

</style>
